<template>

  <v-app>
    <div>
      <v-alert v-model="alert"
               border="left"
               dark
               dismissible
               class="alert"
               :class="{'alert-error':alertType=='error', 'alert-success':alertType=='success'}">
        {{alertMessage}}
      </v-alert>
    </div>
    <div class="row">
      <div class="col-md-6 ml-auto mr-auto">
        <v-container>
          <div class="mb-8 mt-8 text-center">
            <h1>Department</h1>
          </div>
          <v-form ref="department" v-model="valid">
            <v-row no-gutters>
              <v-col cols="12">
                <TextField v-model="formData.Name" :label="'Name'" />
              </v-col>
              <v-col cols="12" class="d-flex justify-end">

                <Button :label="'Submit'"
                        :btnType="'Submit'"
                        @onClick="dialog = true"
                        :disabled="!valid"
                        :isLoading="isSavingUser"
                        class="mr-4" />

                <Button :label="'Reset'" :btnType="'Cancel'" :onClick="onReset" />

              </v-col>
            </v-row>
          </v-form>
          <ConfirmationDialog :title="'Are you sure you want to submit?'" :dialog="dialog" :isLoading="isSavingUser" :btnTitle="'Yes'" @close="dialog = false" @btnAction="onSave"/>
        </v-container>
      </div>
    </div>
  </v-app>

</template>
<script>
import TextField from '@/components/ui/form/TextField.vue'
import Button from '@/components/ui/form/Button.vue'
import ConfirmationDialog from '@/components/ui/confirmationDialog.vue'
import { CREATE_DEPARTMENT } from '@/store/action-type'
export default {
  components: {
    TextField,
    Button,
    ConfirmationDialog
  },
  data () {
    return {
      dialog: false,
      search: '',
      formData: {
        Name: '',
        Action: 'New',
        Status: 0
      },
      valid: false,
      isSavingUser: false,
      alertMessage: '',
      alert: false,
      alertType: ''
    }
  },
  methods: {
    showAlertMessage (message, type) {
      this.alertMessage = message
      this.alert = true
      this.alertType = type
    },
    onSave () {
      this.isSavingUser = true
      this.$store
        .dispatch(CREATE_DEPARTMENT, this.formData)
        .then(() => {
          this.showAlertMessage('Deparment created successful', 'success')
        })
        .catch(() => {
          this.showAlertMessage('Deparment creation failed', 'error')
        })
        .finally(() => {
          this.isSavingUser = false
          this.onReset()
          this.dialog = false
        })
    },
    onReset () {
      this.$refs.department.reset()
    }
  }
}
</script>
<style scoped>
  .search-btn {
    background-color: #e6e3e3;
    height: 40px;
    border-radius: 3px !important;
  }

  .btn-group {
    display: inline-flex;
  }

  .alert {
  position: fixed;
  z-index: 9999;
  top: 5px;
  min-width: 50%;
}
.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}
.alert-error {
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
</style>
